import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import Listing from "../../components/Listing"
import ListingCard from "../../components/ListingCard"
import Seo from "../../components/Seo"

export default function Issue({ data }) {
  //Data destructuring
  const latestIssueData = data.issues.edges[0].node
  const issuesData = data.issues.edges.map(edge => edge.node)
  issuesData.shift()
  //SEO
  const seoTitle = { ru: "Выпуски", fi: "Arkisto" }
  const seoSlug = { current: "arkisto" }

  return (
    <Layout>
      <Seo title={seoTitle} slug={seoSlug} />
      {/* Featured Issue */}
      <section className="featured-issue">
        <p className="section-caption">
          <span className="russian" lang="ru">
            Свежий выпуск
          </span>
          <span className="finnish" lang="fi">
            Tuore numero
          </span>
        </p>
        <ListingCard data={latestIssueData} mode="featured" />
      </section>
      {/* Listing */}
      <section className="all-issues">
        <p className="section-caption">
          <span className="russian" lang="ru">
            Архив
          </span>
          <span className="finnish" lang="fi">
            Arkisto
          </span>
        </p>
        <Listing data={issuesData} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GetIssues {
    issues: allSanityIssue(sort: { fields: number, order: DESC }) {
      edges {
        node {
          _id
          _key
          _type
          covers {
            format
            image {
              asset {
                _id
              }
            }
          }
          description {
            fi
            ru
          }
          link
          number
          title {
            ru
            fi
          }
          slug {
            current
          }
        }
      }
    }
  }
`
